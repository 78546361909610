<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<!-- 证书库 -->
<style scoped>
  .check-distri label {
    margin-bottom: 0;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }

  /deep/.el-select .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px
  }

  /deep/ .el-form-item__content {
    line-height: inherit;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import AddCertKu from "./addCertku.vue"
  import {
    getZsk,
    editZsk,
    deleteZsk,
    addZsk
  } from "@/api/zhengshu/manage.js"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"

  export default {
    components: {
      Layout,
      CheckHeader,
      AddCertKu



    },
    data() {
      return {
        title: " 证书库管理",
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "证书库管理",
            active: true
          }
        ],
        showmodal: false,
        addForm: {},
        dialogTitle: "添加证书库",
        tableList: [],
        secondList: [],
        classList: [],
        years: [],
        exportTitle: "证书库导出",
        module: "ZSKDC",
        // 证书状态
        statusLsit: [{
            name: "已查询",
            val: "1",
          },
          {
            name: "未查询",
            val: "0",
          },
        ],
        getWay: [{
            name: "邮寄",
            val: "1"
          },
          {
            name: "自领取",
            val: "2"
          },


        ],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          nf: "",
          flbm: "",
          lbbm: "",
          keyword: ""
        },

      };
    },
    methods: {
      // 证书记录分页
      getList() {
        getZsk(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getList()
      },
      // 删除
      deleteItem(zskid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteZsk(zskid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      addItem() {
        // this.showmodal = true
        this.dialogTitle = "添加证书库"
        // this.$refs.AddCertKu.show()
      },
      // 打开编辑
      editItem(data) {
        this.dialogTitle = "修改证书库"
        // this.showmodal = true
        this.$refs.AddCertKu.show(data)
      },
      // 提交判断
      submit(e) {
        if (e.zskid) {
          this.editCertKu(e)
        } else {
          this.addCertKu(e)
        }
      },
      // 添加
      addCertKu(obj) {
        let formDate = JSON.parse(JSON.stringify(obj))
        addZsk(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            // this.showmodal = false
            this.$refs.AddCertKu.hide()
            this.getList()
          }

        })
      },
      // 修改
      editCertKu(val) {
        let formDate = JSON.parse(JSON.stringify(val))
        editZsk(formDate).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.$refs.AddCertKu.hide()
            this.getList()
          }
        })
      },
      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      changeflmc() {
        let val = this.pageData.lbbm
        this.getSecondList({
          lbbm: val
        })
      },

      getYear() { //获取年份，当前年前后几年
        var y = new Date().getFullYear();
        for (var i = 0; i <= 5; i++) {
          if (i < 5) {
            this.years.unshift({
              value: (y - i),
              label: (y - i)
            })
          } else {
            for (var x = 1; x < 3; x++) {
              this.years.push({
                value: (y + x),
                label: (y + x)
              })
            }
          }
        }
      },
    },
    mounted() {
      this.getYear()
      this.getClassListPage()
      this.getSecondList()
      this.getList()
    }
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2" style="width:10%;" clearable placeholder="考试年份" v-model="pageData.nf"
                  size="small">
                  <el-option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 12%;" clearable placeholder="考试类别" v-model="pageData.lbbm"
                  size="small" @change="changeflmc">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.value" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 " style="width: 12%;" clearable placeholder="考试二级类别" v-model="pageData.flbm"
                  size="small">
                  <el-option :value="item.flbm" v-for="item in secondList" :key="item.value" :label="item.flmc">
                    {{item.flmc}}
                  </el-option>
                </el-select>
                <input placeholder="关键字" class="h30 form-control border-blue mr-2" maxlength="50" style="width: 10%;"
                  v-model="pageData.keyword" />
                <button type="button" class="btn btn-info h30 flexList mr-2 w-sm " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <a href="/admin/certImport">
                  <b-button variant="outline-info" class="flexList  condition"><i
                      class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加证书库</b-button>
                </a>


              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title:exportTitle, type: 'xlsx', module:module,condition:pageData})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:pageData})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover table-bordered ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th>证书库名称</th>
                    <th style="width: 5%;">年份</th>
                    <th>所有考区</th>
                    <th style="width: 8%;">修改人</th>
                    <th>修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.zskmc}}</td>
                    <td>{{obj.nf}} </td>
                    <td>{{obj.sykq}}</td>
                    <td>
                      {{obj.modifyUser}}
                    </td>
                    <td>
                      {{obj.modifyTime}}
                    </td>
                    <td class="tab-icon ">
                      <!--                      <i class="iconfont icon-edit-two mr-1" @click="editItem(obj)"></i>-->
                      <a :href="'/admin/certImportMatch?zskid='+obj.zskid"><i class="iconfont icon-edit-two "></i></a>
                      <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(obj.zskid,obj.zskmc)"></i>
                    </td>
                  </tr>



                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->

    <!-- 添加证书库 -->
    <AddCertKu @submit="submit" :title="dialogTitle" ref="AddCertKu" v-model="addForm"></AddCertKu>
    <!-- <b-modal id="addZhengshu" v-model="showmodal" :title="this.dialogTitle" centered title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属类别：</label>
              <div class=" col-sm-10 p-0 flexList">
                <select name="lbbm" class="form-control form-select w-100" v-model="addForm.lbbm" @change="getlbmc">
                  <option value="" disabled>请选择所属类别</option>
                  <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
                </select>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属分类：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.flbm" @change="getflmc">
                  <option value="" disabled>请选择分类</option>
                  <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属年份：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.nf">
                  <option v-for="(item, i) in years" :label="item.label" :value="item.value" :key="i">{{item.value}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>证书库名：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" placeholder="请输入类别名称" v-model="addForm.zskmc" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>


    </b-modal> -->
    <!-- 弹窗结束 -->
  </Layout>
</template>
